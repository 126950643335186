export default {
  title: '未来简历-HR推荐的专业AI简历生成工具、轻松搞定专业简历(柏言科技)',
  // title: '',
  meta: [
    {
      name: 'keyword',
      content: '未来简历,AI简历,人工智能简历,印刷简历,高校简历,简历模板,简历制作,简历范文,简历优化,大学生简历'
    },
    {
      name: 'description',
      content: '超级实用的AI简历工具! 500强HR强力推、快速构建专业简历，全新原创专业简历模板、适用不同岗位,高效便捷实用!'
    }
  ]
}