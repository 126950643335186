<template>
  <n-modal v-model:show="globalStore.showVipDialog">
    <div class="vip-content">
      <div class="vip-tab" v-if="payStatus !== PAY_STATUS.SUCCESS">
        <VipServiceList />
        <div class="buy-content">
          <div class="buy-t">会员尊享超多特权</div>
          <div class="person-buy" v-show="!isCdKey">
            <div class="buy-card-box">
              <template v-for="item in vipMenus" :key="item.menu_id">
                <div class="buy-card" :class="{ 'active': activeMenu === item }" @click="onActive(item)">
                  <div class="buy-card-header">
                    <img class="logo" :src="companyConfig.black_logo_url" alt="">
                    <div class="info">{{ item.name }}</div>
                  </div>
                  <div class="buy-card-body">
                    <span class="num2">￥{{ item.now_price }}</span>
                    <span class="num3">￥{{ item.source_price }}</span>
                  </div>
                  <div class="buy-card-footer">{{ item.label }}</div>
                </div>
              </template>
            </div>
            <div class="pay-card">
              <div class="pay-info">
                <div class="text-2">
                  已省{{ preferential || 'XX.XX' }}元<span class="text-3">￥{{ activeMenu?.source_price || 'XX.XX' }}</span>
                </div>
                <div class="text-1">
                  请使用
                  <img class="icon" src="@/assets/img/wx.png" alt="">
                  微信/
                  <img class="icon" src="@/assets/img/zfb.png" alt="">
                  支付宝扫码支付
                </div>
                <div class="text-2">
                  开通表示同意<span class="link">《会员服务协议》</span>
                </div>
                <div class="text-btn">
                  <n-button type="primary" text @click="forceUpdatePayStatus">我已支付</n-button>
                  <n-button type="primary" text @click="isCdKey = true">会员兑换码</n-button>
                </div>
              </div>
              <div class="qrcode">
                <img class="img" v-if="order?.qrcode" :src="order.qrcode" alt="">
                <div class="img-tip" v-if="payStatus === PAY_STATUS.INVALID" @click="initOrder">
                  订单已过期<br>点击刷新
                </div>
              </div>
            </div>
          </div>
          <div class="exchange-vip" v-show="isCdKey">
            <div class="back" @click="isCdKey = false">
              <svg-icon name="arrow-left"></svg-icon>
              返回
            </div>
            <div class="exchange-t">会员兑换码</div>
            <n-input v-model:value="cdKey" size="large" placeholder="请输入会员兑换码"></n-input>
            <n-button type="primary" :disabled="!cdKey" @click="onSubmitCdKey">兑换</n-button>
          </div>
        </div>
      </div>
      <div class="vip-tab" v-else>
        <SuccessTip />
      </div>
      <img class="close-img" src="@/assets/img/close.png" @click="onClose">
    </div>
  </n-modal>
</template>

<script lang="ts" setup>
import { getVipMenus, exchangeCdKey, PAY_STATUS } from '@/api/pay'
import { NModal, NButton, NInput } from 'naive-ui';
import VipServiceList from './vipServiceList.vue'
import SuccessTip from './successTip.vue'
import { useGlobalStore } from '@/store/useGlobalStore'
import { useUserState } from '@/store/useUserStore'
import { ref, watch, computed } from 'vue';
import usePayOrder from './usePayOrder'
const globalStore = useGlobalStore();
const companyConfig = computed(() => {
  return globalStore.companyConfig
})
const onClose = () => {
  globalStore.setShowVipDialog(false);
}

const vipMenus = ref<TVipMenu[]>([])
const getMenus = async () => {
  const { data } = await getVipMenus({});
  if (data) {
    vipMenus.value = data.menus || [];
    onActive(vipMenus.value[0])
  }
}

const activeMenu = ref<TVipMenu>()
const preferential = computed(() => {
  if (!activeMenu.value) {
    return ''
  }
  const { source_price, now_price } = activeMenu.value;
  return (parseFloat(source_price) - parseFloat(now_price)).toFixed(2);
})
const onActive = (menu: TVipMenu) => {
  activeMenu.value = menu;
  initOrder()
}

const {
  initOrder,
  order,
  payStatus,
  forceUpdatePayStatus,
  stop
} = usePayOrder(activeMenu);

watch(
  () => globalStore.showVipDialog,
  (val) => {
    if (val) {
      initState()
    } else {
      stop();
    }
  }
)
const initState = () => {
  // 每次弹窗打开都要初始化
  getMenus()
  payStatus.value = PAY_STATUS.INIT;

  isCdKey.value = false;
  cdKey.value = '';
}

const isCdKey = ref(false);
const cdKey = ref('');
const userStore = useUserState();
const onSubmitCdKey = async () => {
  const { data } = await exchangeCdKey({ cd_key: cdKey.value })
  if (data) {
    stop();
    payStatus.value = PAY_STATUS.SUCCESS;
    userStore.getUserInfo();
  }
}
</script>

<style lang="scss" scoped>
.vip-content {
  width: 1146px;
  height: 696px;
  border-radius: 14px;
  position: relative;
  background-color: #F6F8F9;

  .vip-tab {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;

    .buy-content {
      flex: 1;
      height: 100%;
      overflow: hidden;
      padding: 0 24px;

      .buy-t {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin-top: 38px;
        line-height: 37px;
      }

      .person-buy {
        margin-top: 35px;
      }

      .buy-card-box {
        display: flex;
        align-items: stretch;
      }

      .buy-card {
        flex: 1;
        border: 2px solid #ccc;
        border-radius: 16px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0px 3px 4px 1px rgba(56, 96, 226, 0.1);
        cursor: pointer;

        &.active {
          border-color: var(--primary);

          .buy-card-footer {
            background-color: var(--primary);
            color: #fff;
          }
        }

        &.buy-card {
          margin-left: 16px
        }

        .buy-card-header {
          display: flex;
          align-items: center;
          padding: 26px 40px 0 28px;

          .logo {
            width: 33px;
            height: auto;
            margin-right: 10px;
          }

          .info {
            flex: 1;
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            line-height: 26px;
          }
        }

        .buy-card-body {
          text-align: center;
          margin-top: 45px;
          margin-bottom: 75px;
          color: #000;

          .num2 {
            font-size: 44px;
            line-height: 60px;
            font-weight: bold;
          }

          .num3 {
            margin-left: 4px;
            color: #CCCCCC;
            font-size: 21px;
            line-height: 1;
            text-decoration: line-through;
          }
        }

        .buy-card-footer {
          background-color: #ECECEC;
          color: #999999;
          height: 60px;
          line-height: 60px;
          font-size: 28px;
          text-align: center;
        }
      }

      .pay-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        padding: 28px 56px 28px 40px;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 3px 4px 0px rgba(56, 96, 226, 0.1);
      }

      .qrcode {
        position: relative;
        width: 160px;
        height: 160px;
        border-radius: 6px;
        border: 1px solid #999999;
        overflow: hidden;

        .img {
          width: 100%;
          height: 100%;
        }

        .img-tip {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.9);
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }

      .pay-info {
        .text-1 {
          display: flex;
          align-items: center;
          font-size: 26px;
          line-height: 34px;
          margin: 13px 0;
          white-space: nowrap;

          .icon {
            margin: 0 4px;
          }
        }

        .text-2 {
          color: #999999;
          font-size: 16px;
          line-height: 22px;
        }

        .text-3 {
          margin-left: 8px;
          color: #999999;
          font-size: 14px;
          line-height: 18px;
          text-decoration: line-through;
        }

        .link {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .text-btn {
          margin-top: 22px;

          .n-button+.n-button {
            margin-left: 34px;
          }
        }
      }

      .exchange-vip {
        margin-top: 30px;
        height: 565px;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 3px 4px 0px rgba(56, 96, 226, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 96px;
        position: relative;

        .back {
          position: absolute;
          top: 26px;
          left: 32px;
          font-size: 16px;
          cursor: pointer;

          &:hover {
            color: var(--primary);
          }
        }

        .exchange-t {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 55px;
        }

        .n-input {
          --n-height: 66px !important;
          --n-font-size: 18px !important;
        }

        .n-button {
          margin-top: 96px;
          width: 160px;
          height: 40px;
          font-size: 16px;
        }
      }
    }
  }

  .close-img {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>