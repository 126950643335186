<template lang="pug">
.main 
  frHead
  .main_body
    // 第一部分
    .banner
      .banner_content
        .banner_content_left 
          .txt 🎉 开启未来，从简历开始
          .tit(ref="textEl")
          .des 超越简历专家，让你的简历通过率提高300%！
          n-button(class="free-btn" type="primary" @click="goTemplate") 免费定制专业简历
          .logos
            .des2 受到全球 300 万成功求职者的信赖
            .group
              img(class="company-logo" src="@/assets/img/company/google.svg")
              img(class="company-logo" src="@/assets/img/company/huawei.svg" style="height: 45px")
              img(class="company-logo" src="@/assets/img/company/bytedance.svg")
              img(class="company-logo" src="@/assets/img/company/cmb.svg")
              img(class="company-logo" src="@/assets/img/company/lixiang.svg" style="height: 12px")
        .banner_content_right 
          img(class="resume" src="@/assets/img/resume.svg")
    // 第二部分
    .box.my_resume
      .box_content
        .box_left.typeit_box 
          .resume_tit 
            div 未来简历科技有限公司             
            div 产品运营
            div 2018.7 - 2023.8
          typeit
        .box_right 
          .box_tit 魔法一般、自动构建专业求职简历
          .box_des
            span.bold 智能分析简历:
            | 自动分析用户上传的现有简历和目标职位描述，精准识别关键技能和经验要求。
          .box_des
            span.bold 内容定制优化:
            | 运用 star 法则、针对目标职位进行经历挖掘、能力提炼、成果展现，提升简历的相关性和吸引力。
          .box_des
            span.bold 自动排版重构:
            | 智能调整简历布局，确保简历视觉上的专业性、让HR快速捕捉关键信息。
          .box_des
            span.bold 质量评估修改:
            | 对修改后的简历进行全面评估，包括岗位匹配度和内容的连贯性，确保简历的最高标准。
          n-button(class="free-btn" type="primary" @click="goTemplate") 立即定制简历
    // 第三部分
    .box3.resume_template
      .box3_content
        .box3_left 
          .box3_tit 外观精美，高通过率的简历模板
          .box3_des
            span.bold 专家设计：
            | 每款模板均由行业专家和设计师合作开发，模板既美观又实用。
          .box3_des 
            span.bold 高通过率：
            | 清晰的层次结构确保HR能够轻松浏览您的简历，迅速捕捉关键信息。
          n-button(class="free-btn" type="primary" @click="goTemplate") 立即定制简历
          //- n-button(type="primary" size="large" text @click="goTemplate") 立即体验
        .box3_right 
          n-carousel(
            effect="card"
            prev-slide-style="transform: translateX(-150%) translateZ(-800px);"
            next-slide-style="transform: translateX(50%) translateZ(-800px);"
            style="height:647px;"
            :show-dots="false"
            autoplay
            :interval="1000"
          )
            n-carousel-item(
              :style="{ width: '415px' }"
            )
              img(class="carousel-img" src="@/assets/img/rotation/极简.png")
            n-carousel-item(
              :style="{ width: '415px' }"
            )
              img(class="carousel-img" src="@/assets/img/rotation/恬淡.png")
            n-carousel-item(
              :style="{ width: '415px'}"
            )
              img(class="carousel-img" src="@/assets/img/rotation/大气.png")
            n-carousel-item(
              :style="{ width: '415px'}"
            )
              img(class="carousel-img" src="@/assets/img/rotation/商务.png")
            n-carousel-item(
              :style="{ width: '415px'}"
            )
              img(class="carousel-img" src="@/assets/img/rotation/干练.png")
            
    // 第四部分
    .box.interview_assistant
      .box_content
        .box_left.box4_left.answer_box
          typeitAnswer
        .box_right 
          .box_tit(@click="goHelper") 面试预测助手
          .box_des 结合您的简历内容和求职岗位，为您推荐最有可能在面试中被问到的问题和深入的答案解析，帮助您全面准备。确保您在真实面试中表现出色
          .box_des 持续更新的真实题库
          //- n-button(type="primary" size="large" text @click="goHelper") 立即体验
          n-button(class="free-btn" type="primary" @click="goHelper") 立即预测问题
    // 九宫格
    .nine_block 
      .nine_block_content 
        .line_tit
          .tit 用户评论
          .des 受到超过3,000,000用户的信赖
        .nine_block_content_box
          .nine_block_item 
            .line_item
              .line_txt 我之前用一个通用简历在网上找工作，几乎没收到面试机会。但自从我用未来简历根据岗位信息定制简历后，仅仅一个月，我投递的职位中近一半都给了我面试机会。非常感激！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="gg" )
                .line_name
                  .blod_name David 
                  | 产品经理
          .nine_block_item 
            .line_item
              .line_txt 一份好的简历会收到很多面试邀请!我强烈推荐未来简历。精美的模板设计，非常易于使用！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="pg" )
                .line_name
                  .blod_name John 
                  | 快销管培生 
          .nine_block_item 
            .line_item
              .line_txt 未来简历让我轻松制作出专业级简历，它的智能优化和编辑工具简直是神器，求职路上必备！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="wem" )
                .line_name
                  .blod_name peter 
                  | 市场营销 
          .nine_block_item 
            .line_item
              .line_txt 未来简历的模板设计真的很有感觉，即便是我这样的设计师也觉得眼前一亮。界面友好，操作简单，强烈推荐！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="hw" )
                .line_name
                  .blod_name 苏** 
                  | UI设计师
          .nine_block_item 
            .line_item
              .line_txt 未来简历彻底改变了我对简历制作的认识，简单几步就能制作出专业级简历，绝对是简历神器！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="bd" )
                .line_name
                  .blod_name 周* 
                  | Java开发 
          .nine_block_item 
            .line_item
              .line_txt 用未来简历，不仅节约了我的时间，还大大提升了简历的质量。求职效率提高了不少，强烈推荐！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="sy" )
                .line_name
                  .blod_name 高** 
                  | 行政 
          .nine_block_item 
            .line_item
              .line_txt 感谢未来简历，让我这么快找到了工作。操作简单，效果赞，推荐给每个求职者！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="bank" )
                .line_name
                  .blod_name 赵* 
                  | 风险管理
          .nine_block_item 
            .line_item
              .line_txt 简历小白的救星！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="fz" )
                .line_name
                  .blod_name 王** 
                  | 实习生
          .nine_block_item 
            .line_item
              .line_txt 作为应届毕业生，未来简历太好用了。一步直接优化好，简历质量直线提升，简直不敢相信这是我自己的简历！”
                img(class='line_icon' src="@/assets/img/yin.png")
              .line_partner
                imgIcon(width="122" height='40' srcImgName="tt" )
                .line_name
                  .blod_name 刘** 
                  | 会计核算 
    // 广告词
    .adv_box
      h1.adv_title 为你量身定制专业、高薪简历
      n-button.adv_btn(type="primary" round @click="goTemplate") 创建简历
    HomeFooter(v-if="!companyConfig.hide_footer")
  frFooter(v-if="!companyConfig.hide_footer")
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { NButton, NCarousel, NCarouselItem, useMessage, NModal } from 'naive-ui'
import frHead from '@/components/head/head.vue'
import frFooter from '@/components/footer/footer.vue'
import imgIcon from '@/components/imgIcon/imgIcon.vue'
import typeit from '@/components/typeit/typeit.vue'
import typeitAnswer from '@/components/typeit/typeitAnswer.vue'
import HomeFooter from './components/homeFooter.vue'
import { useRouter } from 'vue-router'
import homeMetaInfo from './homeMetaInfo'
import TypeIt from 'typeit'
import { useGlobalStore } from '@/store/useGlobalStore'

const globalStore = useGlobalStore();
  const companyConfig = computed(() => {
  return globalStore.companyConfig
})

defineOptions({
  metaInfo: homeMetaInfo
})

const textEl = ref<HTMLDivElement>();

onMounted(() => {
  const instance = new (TypeIt as any)(textEl.value, {
    strings: [
      "全新一代AI大模型简历生成工具",
      "轻松构建专业简历",
    ],
    cursorChar: "<span class='cursorChar'>|<span>",//用于光标的字符。HTML也可以
    waitUntilVisible: true,
    speed: 150,
    lifeLike: true,// 使打字速度不规则
    breakLines: false,// 控制是将多个字符串打印在彼此之上，还是删除这些字符串并相互替换
    loop: true,//是否循环
    nextStringDelay:[2000,500],
    loopDelay: [500,2000],
    deleteSpeed: 0,
    cursor: true,//在字符串末尾显示闪烁的光标
  })
  instance.go();
})

const router = useRouter()
const goTemplate = () => {
  router.push('/template-center');
}
const goHelper = () => {
  router.push('/interview-helper');
}
</script>
<style lang="sass" scoped>
.main
  width: 100%
  height: 100%
  position: relative
  background-color: #F7F8FA
  background-image: url('@/assets/img/common/bottom-bg.png')
  background-repeat: no-repeat
  background-size: 100% auto
  background-position: center bottom
  .main_body
    margin: 0 auto
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    .banner
      padding-top: 80px
      width: 100%
      min-width: var(--content-min-width)
      // min-height: 817px
      height: 100vh
      background: url('@/assets/img/common/top-bg.png') no-repeat center
      background-size: cover
      position: relative
      display: flex
      justify-content: center
      .banner_content
        width: 100%
        max-width: var(--content-width)
        min-width: var(--content-min-width)
        padding: 0 var(--content-padding) 0 var(--content-padding)
        display: flex
        justify-content: space-between
        align-items: center
        .banner_content_right
          display: flex
          align-items: center
          justify-content: center
          height: 100%
          .resume
            width: auto
            height: 100%
            max-height: 670px
        .banner_content_left
          position: relative
          display: flex
          flex-direction: column
          justify-content: center
          flex: 1
          height: 100%
          padding-bottom: 120px
          .txt
            font-size: 18px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
            line-height: 1
            padding-left: 4px
          .tit
            font-size: 40px
            font-family: Microsoft YaHei-Bold, Microsoft YaHei
            font-weight: bold
            color: #3860E2
            line-height: 1
            margin-top: 38px
          .des
            max-width: 607px
            font-size: 18px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
            line-height: 30px
            margin-top: 31px
          .free-btn
            width: 300px
            height: 50px
            margin-top: 31px
            font-size: 20px
          .des2
            font-size: 12px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
          .logos
            position: absolute
            left: 0
            bottom: 12px
            .group
              font-weight: 400
              color: #333333
              line-height: 29px
              font-size: 16px
              display: flex
              justify-content: center
              align-items: center
              margin: 15px 31px 0 0
              .company-logo 
                width: auto
                height: 16px
                margin-right: 24px
    .box
      width: 100%
      min-width: var(--content-min-width)
      min-height: 800px
      background: url('@/assets/img/bg2.png') no-repeat center
      background-size: cover
      position: relative
      background-color: #FFFFFF
      display: flex
      justify-content: center
      &.box2
        margin-top: 0
      .box_content
        width: 100%
        max-width: var(--content-width)
        min-width: var(--content-min-width)
        padding: 0 var(--content-padding) 0 var(--content-padding)
        min-height: 800px
        display: flex
        justify-content: space-between
        align-items: center
        .box_left
          width: 494px
          height: 441px
          background: #FFFFFF
          box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16)
          border-radius: 16px
          padding: 55px 42px 0 42px
          .resume_tit
            display: flex
            justify-content: space-between
            font-size: 16px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
            color: #333333
            line-height: 28px
            margin-bottom: 31px
          &.box4_left
            width: 476px
            height: 484px
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            padding: 0px
        .box_right
          flex: 1
          margin-left: 51px
          .box_tit
            font-size: 36px
            font-family: Microsoft YaHei-Bold, Microsoft YaHei
            font-weight: bold
            color: #333333
            line-height: 48px
            margin-left: -2px 
            margin-bottom: 28px
          .box_des
            font-size: 16px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
            line-height: 29px
            margin-bottom: 15px
            .bold
              font-weight: 500
              margin-right: 8px
          .n-button
            font-size: 16px
            line-height: 16px
            margin-top: 19px
    .box3
      margin-top: 75px
      width: 100%
      min-width: var(--content-min-width)
      min-height: 800px
      position: relative
      display: flex
      justify-content: center
      .box3_content
        width: 100%
        max-width: var(--content-width)
        min-width: var(--content-min-width)
        padding: 0 var(--content-padding) 0 var(--content-padding)
        min-height: 800px
        display: flex
        justify-content: space-between
        align-items: center
        .box3_right
          width: 639px
          height: 646px
          display: flex
          align-items: center
          .carousel-img 
            margin: 0 auto
            width: 100%
            height: 100%
            object-fit: contain
        .box3_left
          flex: 1
          margin-right: 51px
          .box3_tit
            font-size: 36px
            font-family: Microsoft YaHei-Bold, Microsoft YaHei
            font-weight: bold
            color: #333333
            line-height: 48px
            margin-bottom: 28px
            margin-left: -2px
          .box3_des
            font-size: 16px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
            line-height: 29px
            margin-bottom: 15px
            .bold
              font-weight: 500
              margin-right: 8px
          .n-button
            font-size: 16px
            line-height: 16px
            margin-top: 19px
    .nine_block
      width: 100%
      min-height: 1420px
      min-width: var(--content-min-width)
      position: relative
      display: flex
      justify-content: center
      .nine_block_content 
        width: 100%
        max-width: var(--content-width)
        min-width: var(--content-min-width)
        padding: 0 var(--content-padding) 0 var(--content-padding)
        min-height: 1411px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-start
        .line_tit
          display: flex
          flex-direction: column
          align-items: center
          .tit
            font-size: 18px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
            color: #FB7243
            line-height: 23px
            margin-top: 59px
          .des
            font-size: 36px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
            color: #1B2124
            line-height: 57px
            margin: 16px 0 50px 0
        .nine_block_content_box
          display: grid
          grid-template-columns: repeat(3, 380px)
          grid-template-rows: repeat(3, 360px)
          grid-row-gap: 26px
          grid-column-gap: 26px
          .nine_block_item
            width: 380px
            height: 360px
            background: #FFFFFF
            box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16)
            border-radius: 0px 0px 0px 0px
            display: flex
            flex-direction: column
            justify-content: space-between
            align-items: center
            padding-top: 35px
            padding-bottom: 35px
            .line_item
              height: 100%
              display: flex
              flex-direction: column
              justify-content: space-between
              align-items: center
              .line_txt
                width: 310px
                height: 106px
                font-size: 14px
                font-family: Microsoft YaHei-Regular, Microsoft YaHei
                font-weight: 400
                color: #333333
                line-height: 28px
                text-indent: 28px
                position: relative
              .line_icon 
                position: absolute
                left: 0
                top: 2px
                width: 23px
                height: 19px
                text-indent: 0
              .line_partner
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                .line_name
                  color: #333333
                  font-size: 16px
                  display: flex
                  .blod_name
                    font-weight: 500
    .adv_box
      width: 100%
      text-align: center
      padding-bottom: 86px
      .adv_title
        font-size: 48px
        font-weight: 400
        height: 64px
        color: #1A1A1A
        margin-bottom: 24px
      .adv_btn
        font-size: 18px
        width: 336px
        height: 44px
</style>